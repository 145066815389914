import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesComponent = () => {
    const [init, setInit] = useState(false);
    const [particleNumber, setParticleNumber] = useState(50); // состояние для количества частиц

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });

        // Функция для изменения количества частиц при изменении ширины экрана
        const handleResize = () => {
            if (window.innerWidth < 780) {
                setParticleNumber(20); // уменьшаем количество частиц для мобильных устройств
            } else {
                setParticleNumber(50); // стандартное количество частиц для больших экранов
            }
        };

        // Вызов при монтировании и добавление обработчика события resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Удаление обработчика при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const particlesLoaded = (container) => {
        // CallBack after particles loading
        // console.log(container);
    };

    return (
        <div>
            {init && (
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={{
                        fpsLimit: 120,
                        interactivity: {
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            move: {
                                enable: true,
                                speed: 2,
                                direction: "none",
                                random: true,
                                straight: false,
                                out_mode: "out",
                                bounce: false,
                            },
                            number: {
                                value: particleNumber, // используем состояние для количества частиц
                                density: {
                                    enable: false,
                                    value_area: 800,
                                },
                            },
                            opacity: {
                                value: 0.6,
                                random: true,
                                anim: {
                                    enable: true,
                                    speed: 0.16,
                                    opacity_min: 0.1,
                                    sync: false,
                                },
                            },
                            shape: {
                                type: "star",
                            },
                            size: {
                                value: 4,
                                random: true,
                                anim: {
                                    enable: false,
                                    speed: 40,
                                    size_min: 0.1,
                                    sync: false,
                                },
                            },
                        },
                        detectRetina: true,
                    }}
                />
            )}
        </div>
    );
};

export default ParticlesComponent;