const cardsData = [
	{ "id": 1, "card_name_eng": "The Fool", "img": "the_fool.jpg" },
	{ "id": 2, "card_name_eng": "The Magician", "img": "the_magician.jpg" },
	{ "id": 3, "card_name_eng": "The High Priestess", "img": "the_high_priestess.jpg" },
	{ "id": 4, "card_name_eng": "The Empress", "img": "the_empress.jpg" },
	{ "id": 5, "card_name_eng": "The Emperor", "img": "the_emperor.jpg" },
	{ "id": 6, "card_name_eng": "The Hierophant", "img": "the_hierophant.jpg" },
	{ "id": 7, "card_name_eng": "The Lovers", "img": "the_lovers.jpg" },
	{ "id": 8, "card_name_eng": "The Chariot", "img": "the_chariot.jpg" },
	{ "id": 9, "card_name_eng": "Strength", "img": "strength.jpg" },
	{ "id": 10, "card_name_eng": "The Hermit", "img": "the_hermit.jpg" },
	{ "id": 11, "card_name_eng": "Wheel of Fortune", "img": "wheel_of_fortune.jpg" },
	{ "id": 12, "card_name_eng": "Justice", "img": "justice.jpg" },
	{ "id": 13, "card_name_eng": "The Hanged Man", "img": "the_hanged_man.jpg" },
	{ "id": 14, "card_name_eng": "Death", "img": "death.jpg" },
	{ "id": 15, "card_name_eng": "Temperance", "img": "temperance.jpg" },
	{ "id": 16, "card_name_eng": "The Devil", "img": "the_devil.jpg" },
	{ "id": 17, "card_name_eng": "The Tower", "img": "the_tower.jpg" },
	{ "id": 18, "card_name_eng": "The Star", "img": "the_star.jpg" },
	{ "id": 19, "card_name_eng": "The Moon", "img": "the_moon.jpg" },
	{ "id": 20, "card_name_eng": "The Sun", "img": "the_sun.jpg" },
	{ "id": 21, "card_name_eng": "Judgement", "img": "judgement.jpg" },
	{ "id": 22, "card_name_eng": "The World", "img": "the_world.jpg" },
	{ "id": 23, "card_name_eng": "Ace of Wands", "img": "ace_of_wands.jpg" },
	{ "id": 24, "card_name_eng": "Two of Wands", "img": "two_of_wands.jpg" },
	{ "id": 25, "card_name_eng": "Three of Wands", "img": "three_of_wands.jpg" },
	{ "id": 26, "card_name_eng": "Four of Wands", "img": "four_of_wands.jpg" },
	{ "id": 27, "card_name_eng": "Five of Wands", "img": "five_of_wands.jpg" },
	{ "id": 28, "card_name_eng": "Six of Wands", "img": "six_of_wands.jpg" },
	{ "id": 29, "card_name_eng": "Seven of Wands", "img": "seven_of_wands.jpg" },
	{ "id": 30, "card_name_eng": "Eight of Wands", "img": "eight_of_wands.jpg" },
	{ "id": 31, "card_name_eng": "Nine of Wands", "img": "nine_of_wands.jpg" },
	{ "id": 32, "card_name_eng": "Ten of Wands", "img": "ten_of_wands.jpg" },
	{ "id": 33, "card_name_eng": "Page of Wands", "img": "page_of_wands.jpg" },
	{ "id": 34, "card_name_eng": "Knight of Wands", "img": "knight_of_wands.jpg" },
	{ "id": 35, "card_name_eng": "Queen of Wands", "img": "queen_of_wands.jpg" },
	{ "id": 36, "card_name_eng": "King of Wands", "img": "king_of_wands.jpg" },
	{ "id": 37, "card_name_eng": "Ace of Cups", "img": "ace_of_cups.jpg" },
	{ "id": 38, "card_name_eng": "Two of Cups", "img": "two_of_cups.jpg" },
	{ "id": 39, "card_name_eng": "Three of Cups", "img": "three_of_cups.jpg" },
	{ "id": 40, "card_name_eng": "Four of Cups", "img": "four_of_cups.jpg" },
	{ "id": 41, "card_name_eng": "Five of Cups", "img": "five_of_cups.jpg" },
	{ "id": 42, "card_name_eng": "Six of Cups", "img": "six_of_cups.jpg" },
	{ "id": 43, "card_name_eng": "Seven of Cups", "img": "seven_of_cups.jpg" },
	{ "id": 44, "card_name_eng": "Eight of Cups", "img": "eight_of_cups.jpg" },
	{ "id": 45, "card_name_eng": "Nine of Cups", "img": "nine_of_cups.jpg" },
	{ "id": 46, "card_name_eng": "Ten of Cups", "img": "ten_of_cups.jpg" },
	{ "id": 47, "card_name_eng": "Page of Cups", "img": "page_of_cups.jpg" },
	{ "id": 48, "card_name_eng": "Knight of Cups", "img": "knight_of_cups.jpg" },
	{ "id": 49, "card_name_eng": "Queen of Cups", "img": "queen_of_cups.jpg" },
	{ "id": 50, "card_name_eng": "King of Cups", "img": "king_of_cups.jpg" },
	{ "id": 51, "card_name_eng": "Ace of Swords", "img": "ace_of_swords.jpg" },
	{ "id": 52, "card_name_eng": "Two of Swords", "img": "two_of_swords.jpg" },
	{ "id": 53, "card_name_eng": "Three of Swords", "img": "three_of_swords.jpg" },
	{ "id": 54, "card_name_eng": "Four of Swords", "img": "four_of_swords.jpg" },
	{ "id": 55, "card_name_eng": "Five of Swords", "img": "five_of_swords.jpg" },
	{ "id": 56, "card_name_eng": "Six of Swords", "img": "six_of_swords.jpg" },
	{ "id": 57, "card_name_eng": "Seven of Swords", "img": "seven_of_swords.jpg" },
	{ "id": 58, "card_name_eng": "Eight of Swords", "img": "eight_of_swords.jpg" },
	{ "id": 59, "card_name_eng": "Nine of Swords", "img": "nine_of_swords.jpg" },
	{ "id": 60, "card_name_eng": "Ten of Swords", "img": "ten_of_swords.jpg" },
	{ "id": 61, "card_name_eng": "Page of Swords", "img": "page_of_swords.jpg" },
	{ "id": 62, "card_name_eng": "Knight of Swords", "img": "knight_of_swords.jpg" },
	{ "id": 63, "card_name_eng": "Queen of Swords", "img": "queen_of_swords.jpg" },
	{ "id": 64, "card_name_eng": "King of Swords", "img": "king_of_swords.jpg" },
	{ "id": 65, "card_name_eng": "Ace of Pentacles", "img": "ace_of_pentacles.jpg" },
	{ "id": 66, "card_name_eng": "Two of Pentacles", "img": "two_of_pentacles.jpg" },
	{ "id": 67, "card_name_eng": "Three of Pentacles", "img": "three_of_pentacles.jpg" },
	{ "id": 68, "card_name_eng": "Four of Pentacles", "img": "four_of_pentacles.jpg" },
	{ "id": 69, "card_name_eng": "Five of Pentacles", "img": "five_of_pentacles.jpg" },
	{ "id": 70, "card_name_eng": "Six of Pentacles", "img": "six_of_pentacles.jpg" },
	{ "id": 71, "card_name_eng": "Seven of Pentacles", "img": "seven_of_pentacles.jpg" },
	{ "id": 72, "card_name_eng": "Eight of Pentacles", "img": "eight_of_pentacles.jpg" },
	{ "id": 73, "card_name_eng": "Nine of Pentacles", "img": "nine_of_pentacles.jpg" },
	{ "id": 74, "card_name_eng": "Ten of Pentacles", "img": "ten_of_pentacles.jpg" },
	{ "id": 75, "card_name_eng": "Page of Pentacles", "img": "page_of_pentacles.jpg" },
	{ "id": 76, "card_name_eng": "Knight of Pentacles", "img": "knight_of_pentacles.jpg" },
	{ "id": 77, "card_name_eng": "Queen of Pentacles", "img": "queen_of_pentacles.jpg" },
	{ "id": 78, "card_name_eng": "King of Pentacles", "img": "king_of_pentacles.jpg" }
];

export default cardsData;