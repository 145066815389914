import "./Loader.scss";
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../../context/LanguageContext";
import { useEffect, useRef  } from "react";

const Loader = ({is_active}) => {
	const { t } = useTranslation();
	const { language } = useLanguage();

	const adRef = useRef(null);

	useEffect(() => {
	  if (window.adsbygoogle && adRef.current) {
		try {
		  (window.adsbygoogle = window.adsbygoogle || []).push({});
		} catch (e) {
		  console.error("AdSense error", e);
		}
	  }
	}, []);

	return (
		<div className={is_active ? 'loading fadeIn' : 'loading fadeOut'}>
			<div className='loading-text'>{t('Loading')}</div>
			<div className='loader-47'>
				<div className='square-1'></div>
				<div className='square-2'></div>
			</div>

			{/* <!-- Loading --> */}
			<ins class="adsbygoogle"
				style={{display: 'block'}}
				data-ad-client="ca-pub-8564232655386438"
				data-ad-slot="2189746383"
				data-ad-format="auto"
				data-full-width-responsive="true"></ins>
		</div>
	)
}

export default Loader;