import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import i18n from './i18n'; // Подключение i18n
import TarotApp from './components/Taro';
import Layout from './components/Layout';
import TaroOracleYesNo from './pages/TaroOracleYesNo/TaroOracleYesNo';
// import TaroCelticCross from './pages/TaroCelticCross/TaroCelticCross';
import TaroHorseshoe from './pages/TaroHorseshoe/TaroHorseshoe';
// import TaroTreeOfLife from './pages/TaroTreeOfLife/TaroTreeOfLife';
// import TaroLoveTriangle from './pages/TaroLoveTriangle/TaroLoveTriangle';
import HomePage from './pages/HomePage/HomePage';
import TaroThreeCards from './pages/TaroThreeCards/TaroThreeCards';

const availableLanguages = ['en', 'ru', 'ua']; // Список поддерживаемых языков

const createLanguageRoutes = (lang) => {
	return {
		path: `/${lang}`,
		element: (<Layout />),
		children: [
			{
				path: "",
				element: <HomePage />,
			},
			{
				path: "oracle-yes-no",
				element: <TaroOracleYesNo />,
			},
			{
				path: "three-cards",
				element: <TaroThreeCards />,
			},
			{
				path: "horseshoe",
				element: <TaroHorseshoe />,
			},
			{
				path: "TarotApp",
				element: <TarotApp />,
			},
			// {
			// 	path: "celtic-cross",
			// 	element: <TaroCelticCross />,
			// },
			// {
			// 	path: "tree-of-life",
			// 	element: <TaroTreeOfLife />,
			// },
			// {
			// 	path: "love-triangle",
			// 	element: <TaroLoveTriangle />,
			// },
		],
	};
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to={`/${i18n.language || 'en'}`} />, // Перенаправление на язык по умолчанию
	},
	...availableLanguages.map(createLanguageRoutes), // Генерация маршрутов для каждого языка
]);

export default router;
