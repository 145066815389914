import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import "./TaroOracleYesNo.scss";
import { useLanguage } from '../../context/LanguageContext';
import cardsData from "../../Data/cardsData";
import Loader from '../../components/Loader/Loader';
import Separator from '../../components/Separator/Separator';
import CardsListSlickSlider from '../../components/CardsListSlickSlider/CardsListSlickSlider';
import config from '../../config.js'; 

const shuffleArray = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

function TaroOracleYesNo() {
	const api_url = config.IS_LOCAL ? config.API_LOCAL : config.API_SERVER;
	const { t } = useTranslation();
	const { language } = useLanguage();
	const [ question, setQuestion ] = useState('');
	const [ cardsList, setCardsList ] = useState([]);
	const max_cards = 1;

	const SpreadMap = {
		title: t('menu_TaroOracleYesNo'),
		type: 'TaroOracleYesNo',
		no_card_map: true,
		cards_desc: {
			0: t('map_TaroOracleYesNo_c1'),
		}	
	}

	const faqs = [
		t("faq_OracleYesNo1"),
		t("faq_OracleYesNo2"),
		t("faq_OracleYesNo3"),
		t("faq_OracleYesNo4"),
		t("faq_OracleYesNo5"),
		t("faq_OracleYesNo6"),
		t("faq_OracleYesNo7"),
		t("faq_OracleYesNo8"),
		t("faq_OracleYesNo9"),
		t("faq_OracleYesNo10"),
	]

	const [ popupInfo, setPopupInfo ] = useState({card: []});

	const [ popupActive, setPopupActive ] = useState(false);
	const [ isLoading , setIsLoading ] = useState(false);
	const [ cardsListLength, setCardsListLength ] = useState(0);
	const [ cardOrder, setCardOrder ] = useState(shuffleArray([...Array(72)].map((_, i) => i + 1)));
	const [ selectedFaq, setSelectedFaq ] = useState('');

	const getRandomCards = (number = 1) => {
		const shuffledCards = shuffleArray([...cardOrder]);
		const selected = shuffledCards.slice(0, number);
		return selected;
	};

	const handlePopup = () => {
		if(popupActive) {
			// Clear states for new question
			setCardOrder(shuffleArray([...Array(72)].map((_, i) => i + 1)));
			setSelectedFaq('');
			setCardsListLength(0);
			setPopupInfo({card: []});
			setQuestion('');
			setCardsList([]);
		}

		setPopupActive(!popupActive);
	}

	const handleAutoChoice = (e) => {
		e.preventDefault();
		// e.target.disabled = true;
		const needed_cards = max_cards - cardsList.length;
		const random_cards = getRandomCards(needed_cards);
		
		random_cards.map((card_index, index) => {
			setTimeout(() => {			
				addCardToSelectedList(card_index, index + cardsList.length + 1);				
			}, 750 * index);
		});
	}

	const handleActiveCard = (e) => {
		const card_id = e.target.getAttribute('index');
		e.preventDefault();
		
		addCardToSelectedList(card_id, cardsListLength + 1);
	}

	const addCardToSelectedList = (card_index, cards_list_length = cardsListLength ) => {
		setCardsListLength(cards_list_length);
		setCardsList(cardsList => [...cardsList, {
			"id": Number(card_index) + 1,
			"stack_id": cards_list_length,
			"card_name": cardsData[card_index].card_name_eng,
			"meanning": SpreadMap.cards_desc[cards_list_length],
			"img": cardsData[card_index].img,
		}]);
	}

	const handleSelectChange = (event) => {
		setQuestion(event.target.value);
		setSelectedFaq(event.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const res = await axios.post(`${api_url}api/taro-oracle-yes-no`, { question: question, lang: language, cards: cardsList });

			setPopupInfo(res.data);
			setIsLoading(false);
			setPopupActive(true);
		} catch (error) {
			console.error("Error fetching tarot reading:", error);
		}
	};

	return(
		<>
		<Helmet>
			<title>{t('TaroOracleYesNo_Title_Tag')}</title>
			<meta name="description" content={t('TaroOracleYesNo_Meta_Description')} />
			<meta name="keywords" content={t('TaroOracleYesNo_Main_Keywords')} />
		</Helmet>
		
		<div className="tarot-app bootstrap-wrapper TaroOracleYesNo tr-scroll">
			<Loader is_active={isLoading} />

			<div className={popupActive || isLoading ? `container fadeOut` : `container fadeIn`}>
				<div className="taro-title">
					<h1>{t('TaroOracleYesNo_H1')}</h1>
				</div>

				<div className="row row-main align-items-center">
					<div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
						<div className="taro-cards-map">
							{[...Array(max_cards)].map((e, index) => (
								<div className={`cardBlock index${index+1}`} key={index+1}>
									<div className="cardIndex">{index+1}</div>
									<div className='cardDescription'>{SpreadMap.cards_desc[index]}</div>

									<div className='rotate-container'>
										<div className={`card-front ${cardsListLength >= index+1 ? 'rotate-card-front' : 'rotate-card-back'}`}>
											<img src='../images/cards/card_default.jpg' alt='card taro'/>
										</div>

										<div className={`cardBlock card-back ${cardsListLength >= index+1 ? 'rotate-card-back' : 'rotate-card-front'}`}>
											<img src={cardsListLength >= index+1 ? `../images/cards/${cardsList[index].img}` : '../images/cards/card_default.jpg'} />
										</div>									
										
									</div>
								</div>
							))}
						</div>

					</div>

					<div className="col-12">
						<div className={cardsListLength === max_cards ? `cards-stack unavalible fadeOut-anim1` : `cards-stack`}>
							{[...Array(72)].map((e, index) => (
								<div 
									className={
										cardsList.some(card => card.id === index+1)
											? `card index${index+1} active`
											: `card index${index+1} `
									} 
									key={index+1} 
									index={index+1} 
									style={{ order: cardOrder[index+1] }}
									onClick={handleActiveCard}
								>
									<img src='../images/cards/card_default.jpg' alt='card taro'/>
								</div>
							))}
						</div>

						<div className={cardsListLength === max_cards ? `cards-stack-title unavalible` : `cards-stack-title`}>
							<div className="text">{t('select_cards')} {max_cards}/{cardsListLength}</div>
							<button 
								disabled={cardsListLength === max_cards} 
								className='btn btn-outline-gold' 
								onClick={handleAutoChoice}
							>{t('auto_choice')}</button>
						</div>
					</div>

					{/* all cals list: col-12 col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-6 */}
					<div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
						<div className="question-form">
							<div className='taro-form-container'>
								<div className='taro-form size-small'>
									<div className='form-bg' style={{background: "url('../images/TaroForm/form-bg.png') repeat"}}></div>
									<div className='form-left' style={{background: "url('../images/TaroForm/form-left.png') repeat-y"}}></div>
									<div className='form-righ' style={{background: "url('../images/TaroForm/form-right.png') repeat-y"}}></div>
									<div className='form-top' style={{background: "url('../images/TaroForm/form-top.png') repeat-x"}}></div>
									<div className='form-top-left' style={{background: "url('../images/TaroForm/form-top-left.png')"}}></div>
									<div className='form-top-right' style={{background: "url('../images/TaroForm/form-top-right.png') repeat-y"}}></div>

									<div className='form-content'>
										<div className='form-title'>
											<h1>{t('Ask me')}</h1>
										</div>

										<div className='form-block'>
											<form onSubmit={handleSubmit}>
												<textarea
													value={question}
													onChange={(e) => setQuestion(e.target.value)}
													placeholder={t('input_hint')}
													></textarea>
													
													<div className='faqSelector'>
														<div className='faqSelector__title'>* {t('faq_title')}</div>
														<select id="faq-select" onChange={handleSelectChange}>
															<option value={selectedFaq}>{t('faq_Choose_a_question')}</option>
															{faqs.map((faq, index) => (
																<option key={index} value={faq} disabled={faq.startsWith('--')}>
																	{faq}
																</option>
															))}
														</select>
													</div>

												<button className='btn gold' type="submit" disabled={!(cardsListLength === max_cards && question)}>
													{t('button_start')}
												</button>
											</form>
										</div>
									</div>

									<div className='form-bottom' style={{background: "url('../images/TaroForm/form-bottom.png') repeat-x"}}></div>
									<div className='form-bottom-left' style={{background: "url('../images/TaroForm/form-bottom-left.png')"}}></div>
									<div className='form-bottom-right' style={{background: "url('../images/TaroForm/form-bottom-right.png')"}}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={popupActive ? `popup-wrap fadeIn` : `popup-wrap fadeOut`}>
				<button className="close-btn" onClick={handlePopup}>
					<span className="top"></span>
					<span className="bot"></span>
				</button> 
				
				<div className={popupActive ? `popup-box transform-in` : `popup-box transform-out`}>
					<div className='container'>
						<div className='row'>
							{popupActive ? 
								<CardsListSlickSlider 
									data={popupInfo} 
									cardsList={cardsList} 
									SpreadMap={SpreadMap} 
									isOpen={popupActive} 
									conslusion={popupInfo}
								/>
								: ''}							

							<div className='col-12 cards_footer'>
								<Separator style={{'margin-bottom': '40px'}}/>
								<div className='button_block'>
									<button className="btn gold hide-cards" onClick={handlePopup}>
										{t('btn_new_fortune_telling')}
									</button> 
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default TaroOracleYesNo;