import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

const LanguageSelector = () => {
	const navigate = useNavigate();
	const { language, setLanguage } = useLanguage(); // Используем хук контекста

	const changeLanguage = (lng) => {
		setLanguage(lng); // Обновляем контекст
		navigate(`/${lng}`); // Перенаправляем на соответствующий URL
	};

	return (
		<div className='language-selector'>
			<select onChange={(e) => changeLanguage(e.target.value)} value={language}>
				<option value="en">English</option>
				<option value="ru">Русский</option>
				<option value="ua">Українська</option>
			</select>
		</div>
	);
};

export default LanguageSelector;