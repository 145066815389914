import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cardsData from "../../Data/cardsData";
import Separator from '../Separator/Separator';

const CardsSlick = ({data, isActive, img}) => {
	const [cardClass, setCardClass] = useState();
	const { t } = useTranslation();

	useEffect(() => {
		setTimeout(() => {
			setCardClass(isActive);
		}, 500);

	}, [isActive]);

	return (
		<div className='CardsSlick row'>
			<div className='cardTitle'>
				{t(`card_name_${data.id}`)}
				<Separator />
			</div>
		
			<div className='CardsSlick_block col-12 row'>
				<div className='Cards_image rotate-container col-6 col-xs-6 col-sm-6 col-md-6'>
					<div className={`card-front ${cardClass ?  'rotate-card-back' : 'rotate-card-front'}`}>
						<div className='cardImage'>
							<img src='../images/cards/card_default.jpg' alt='card taro'/>
						</div>
					</div>

					<div className={`cardBlock card-back ${cardClass ? 'rotate-card-front' : 'rotate-card-back'}`}>					
						<div className='cardImage'>
							<img src={img} alt={data.card_name_eng} />
						</div>
					</div>
				</div>

				<div className='col-6 col-xs-12 d-sm-none'>
					<div className='CardDescription'>
						{t(`card_desc_${data.id}`).split(',').map((word, index) => <span className='tag_word' key={`tag_word-${index}`}>{word}</span>)}
					</div>
				</div>

				<div className='Card_coontent col-12 col-xs-6 col-sm-6 col-md-6'>
					<div className='CardDescription d-none d-sm-flex'>
						{t(`card_desc_${data.id}`).split(',').map((word, index) => <span className='tag_word' key={`tag_word-${index}`}>{word}</span>)}
					</div>
					<div className='cardConclusion'>
						<p>{data.conclusion}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardsSlick;
