import React, { useState, useRef } from 'react';
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardsListSlickSlider.scss";
import CardsSlick from './CardsSlick';
import Separator from '../Separator/Separator';

const CardsListSlickSlider = ({data, conslusion, SpreadMap, cardsList}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const { t } = useTranslation();	
	const sliderRef = useRef(null);
  
	const handleAfterChange = (index) => {
		const slide = document.querySelector(`.slick-slide[data-index="${index}"] .slide-content`);
		if (slide) {
			slide.classList.add('active');
		}
	};
    
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		beforeChange: (current, next) => setCurrentSlide(next),
		afterChange: handleAfterChange
	};
  
	return (
	 	<div className={`CardsListSlickSlider ${SpreadMap.type}`}>
			<Slider ref={sliderRef} {...settings}>

				{!SpreadMap?.no_card_map ? 
					<div className='CardsSlick card_map'>
						<div className='cardTitle'>
							{SpreadMap.title}
							<Separator />
						</div>
						
						<div className={`taro-cards-map ${SpreadMap.type}`}>
							{data.cards.map((e, index) => (
								<div className={`cardBlock index${index+1}`} key={index+1}>
									<div className="cardIndex">{index+1}</div>
									<div className='cardDescription'>{SpreadMap.cards_desc[index]}</div>

									<img src={`../images/cards/${cardsList[index].img}`} />	
								</div>
							))}
						</div>
					</div>
				: ''}

				{data.cards.map((el, index)=> (
					<CardsSlick 
						data={el} 
						isActive={SpreadMap.type === 'TaroOracleYesNo' ? false : index === currentSlide} 
						index={index}
						img={`../images/cards/${cardsList[index].img}`}
						key={`card-${index}`}
					/>
				))}

				<div className='CardsSlick conclusion'>
					<div className='cardTitle'>
						{t('general_conclusion')}
						<Separator />
					</div>
					<div className='general_conclusion'>
						{conslusion.general_conclusion}
					</div>
				</div>
			</Slider>
			<div className="slide-number">
				<button className={currentSlide + 1 === 1 ? 'btn_arrow btn_prev fadeOut' : 'btn_arrow btn_prev fadeIn'} onClick={() => sliderRef.current.slickPrev()}>
					<img className="arrow_img" src="../images/arrow.svg" alt="arrow" />
				</button>

				<div className='numbers'>
					<span className='current_number'>{currentSlide + 1}</span>
					<span className='amount_number'>/ {SpreadMap?.no_card_map ? cardsList.length + 1 : cardsList.length + 2}</span>
				</div>
				
				<button className={currentSlide + 1 === cardsList.length + 2 ? 'btn_arrow btn_next fadeOut' : 'btn_arrow btn_next fadeIn'} onClick={() => sliderRef.current.slickNext()}>
					<img className="arrow_img" src="../images/arrow.svg" alt="arrow" />
				</button>
			</div>
	  	</div>
	);
};

export default CardsListSlickSlider;
