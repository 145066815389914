import React, { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../hooks/useLocalStorage';

// Создаем контекст
const LanguageContext = createContext();

// Хук для использования контекста
export const useLanguage = () => useContext(LanguageContext);

// Провайдер для контекста
export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useLocalStorage('language', i18n.language);

  // Синхронизация языка с i18n
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
