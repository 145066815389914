import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import "./HomePage.scss";
import { NavLink } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

const HomePage = () => {
	const { t } = useTranslation();
	const { language } = useLanguage();
	const home_nav = [
		{
			title: t('menu_TaroOracleYesNo'),
			img: 'nav_yes_no_1.png',
			link: 'oracle-yes-no'
		},
		{
			title: t('menu_TaroThreeCards'),
			img: 'nav_three_cards.png',
			link: 'three-cards'
		},
		{
			title: t('menu_TaroHorseshoe'),
			img: 'nav_Horseshoe.png',
			link: 'horseshoe'
		},
	]

	return (
		<>
		<Helmet>
			<title>{t('Home_Title_Tag')}</title>
			<meta name="description" content={t('Home_Meta_Description')} />
			<meta name="keywords" content={t('Home_Main_Keywords')} />
		</Helmet>
		
		<div className="tarot-app bootstrap-wrapper HomePage tr-scroll">
			<div className="container">
				<div className="row row-main flex-column align-items-center flex-nowrap">
					<div className="col-12 page-logo">
						<div className='logo'>
							<img src={`../images/taro-reader--logo.png`} alt="taro reader, logo" />	
							<h1 className='logo-title'>{t('Home_H1')}</h1>
							{/* <div className='logo-description'>Онлайн гадание на картах Таро и разбор карт таро исходя из вашего вопроса</div> */}
						</div>
					</div>
					<div className='col-12 home_nav--title'>
						<h2>{t('Home_H2')}</h2>
					</div>
					<div className='col-12 row home_nav'>
						{home_nav.map((nav, index) => (
							<NavLink className="col-12" to={`/${language}/${nav.link}`} key={`nav-${index}`}>
								<div className='nav_img btn btn_white'>
									<img src={`../images/nav_icons/${nav.img}`} />	
									<div className='nav_title'>
										{nav.title}
									</div>
								</div>
							</NavLink>
							)
						)}
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default HomePage;
