// TODO: The component use as first example, and can be deleted with a time
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CardList from './CardsList/CardList';
import Separator from './Separator/Separator';
import CardsListSlickSlider from './CardsListSlickSlider/CardsListSlickSlider';

// language
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import Loader from './Loader/Loader';

function TarotApp() {
	// Language part
	const { t } = useTranslation();
	const { language } = useLanguage();
	const faqs = [
		t("faq_Relationship"),
		t("faq_Relationship_q1"),
		t("faq_Relationship_q2"),
		
		t("faq_Career"),
		t("faq_Career_q1"),
		t("faq_Career_q2"),
		
		t("faq_Finance"),
		t("faq_Finance_q1"),
		t("faq_Finance_q2"),

		t("faq_Personal_growth"),
		t("faq_Personal_growth_q1"),
		t("faq_Personal_growth_q2")
	]

	// Language part -- end
	
	const [question, setQuestion] = useState('');
	const [selectedFaq, setSelectedFaq] = useState('');
	const [cardsList, setCardsList] = useState([]);
	const [popupActive, setPopupActive] = useState(false);
	const [isLoading , setIsLoading] = useState(false);
	const [popupInfo, setPopupInfo] = useState({card: []});

	const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 992);
		};
	
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	

	const hadlePopup = () => {
		setPopupActive(!popupActive);
		setQuestion('');
		setSelectedFaq('');
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			// const res = await axios.post('http://localhost:3001/api/random-tarot', { question: question, lang: language });
			const res = await axios.post('https://api.taro-reader.com/api/random-tarot', { question: question, lang: language });

			console.log('res', res);
			setPopupInfo(res.data);
			setCardsList(res.data.card)
			setPopupActive(true);
			setIsLoading(false);
			// setResponse(res.data.response);
		} catch (error) {
			console.error("Error fetching tarot reading:", error);
		}
	};

	const handleSelectChange = (event) => {
		setQuestion(event.target.value);
		setSelectedFaq(event.target.value);
	};

	return (
		<div className="tarot-app bootstrap-wrapper">
			<Loader is_active={isLoading} />

			<div className={popupActive || isLoading ? `container fadeOut` : `container fadeIn`}>
				<div className="row justify-content-center">

					{/* TODO: rebuild in separate component */}
					<div className="taro-form align-self-center col-10 col-xs-10 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-5">

						<div className='form-bg' style={{background: "url('./images/TaroForm/form-bg.png') repeat"}}></div>
						<div className='form-left' style={{background: "url('./images/TaroForm/form-left.png') repeat-y"}}></div>
						<div className='form-righ' style={{background: "url('./images/TaroForm/form-right.png') repeat-y"}}></div>
						<div className='form-top' style={{background: "url('./images/TaroForm/form-top.png') repeat-x"}}></div>
						<div className='form-top-left' style={{background: "url('./images/TaroForm/form-top-left.png')"}}></div>
						<div className='form-top-right' style={{background: "url('./images/TaroForm/form-top-right.png') repeat-y"}}></div>
						<div className='form-top-middle' style={{background: "url('./images/TaroForm/form-top-middle.png') repeat-y"}}></div>

							<div className='form-content'>
								<div className='form-title'>
									<h1>{t('Ask me')}</h1>
								</div>

								<div className='form-block'>
									<form onSubmit={handleSubmit}>

										<textarea
											value={question}
											onChange={(e) => setQuestion(e.target.value)}
											placeholder={t('input_hint')}
										></textarea>

										<div className='faqSelector'>
											<div className='faqSelector__title'>* {t('faq_title')}</div>
											<select id="faq-select" onChange={handleSelectChange}>
												<option value={selectedFaq}>{t('faq_Choose_a_question')}</option>
												{faqs.map((faq, index) => (
													<option key={index} value={faq} disabled={faq.startsWith('--')}>
														{faq}
													</option>
												))}
											</select>
										</div>
										
										<button className='btn gold' type="submit" disabled={!question} >
											{t('button_start')}
										</button>
									</form>
								</div>

							</div>

						<div className='form-bottom' style={{background: "url('./images/TaroForm/form-bottom.png') repeat-x"}}></div>
						<div className='form-bottom-left' style={{background: "url('./images/TaroForm/form-bottom-left.png')"}}></div>
						<div className='form-bottom-right' style={{background: "url('./images/TaroForm/form-bottom-right.png')"}}></div>
						<div className='form-bottom-middle' style={{background: "url('./images/TaroForm/form-bottom-middle.png')"}}></div>

					</div>
				</div>
			</div>

			<div className={popupActive ? `popup-wrap fadeIn` : `popup-wrap fadeOut`}>
				<button className="close-btn" onClick={hadlePopup}>
					<span className="top"></span>
					<span className="bot"></span>
				</button> 
				
				<div className={popupActive ? `popup-box transform-in` : `popup-box transform-out`}>
					<div className='container'>
						<div className='row'>
							{!isMobile ? 
								<CardList data={cardsList} isOpen={popupActive} conslusion={popupInfo} />
								: 
								<CardsListSlickSlider data={cardsList} isOpen={popupActive} conslusion={popupInfo}/>
							}

							<div className='col-12 cards_footer'>
								<Separator style={{'margin-bottom': '40px'}}/>
								<div className='button_block'>
									<button className="btn gold hide-cards" onClick={hadlePopup}>
										{t('btn_new_fortune_telling')}
									</button> 
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default TarotApp;
