import './App.css';
import './Fonts.css';
import './bootstrap-5.3.3-dist/css/bootstrap-grid.min.css';
import './bootstrap-5.3.3-dist/css/bootstrap-grid.min.css';

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import router from './router';
import { RouterProvider } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import config from './config.js';
import { Helmet } from 'react-helmet';

function App() {
	useEffect(() => {
		if(!config.IS_LOCAL) {
			// Подключаем gtag.js
			const script = document.createElement('script');
			script.src = `https://www.googletagmanager.com/gtag/js?id=${config.GOOGLE_TRACK_ID}`;
			script.async = true;
			document.head.appendChild(script);
		
			// Инициализируем Google Tag
			window.dataLayer = window.dataLayer || [];
			function gtag(){ window.dataLayer.push(arguments); }
			gtag('js', new Date());
			gtag('config', config.GOOGLE_TRACK_ID);


			 // Создаем и добавляем скрипт Google AdSense
			 const script_ads = document.createElement('script');
			 script_ads.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8564232655386438";
			 script_ads.async = true;
			 script_ads.crossOrigin = "anonymous";
			 document.head.appendChild(script_ads);
		 
			 // Очистка: Удаляем скрипт при размонтировании компонента
			 return () => {
			   document.head.removeChild(script_ads);
			 };
			
		}
	  }, []);

	return (
		<div className="App">
			<Helmet>
				<meta name="google-adsense-account" content="ca-pub-8564232655386438"></meta>
				<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8564232655386438"
     crossorigin="anonymous"></script>
			</Helmet>
			
			<LanguageProvider>
				<RouterProvider router={router} />
			</LanguageProvider>
		</div>
	);
}

export default App;


