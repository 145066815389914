import React from 'react';
import TaroBg from './TaroBg/TaroBg';
import { Outlet } from 'react-router-dom';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import OffCanvas from './OffCanvas/OffCanvas';

const Layout = () => {

  return (
    <div>
		<TaroBg />
		<LanguageSelector />
		<OffCanvas />

		<main>
			<Outlet />
		</main>
    </div>
  );
};

export default Layout;
