import React, { useState } from 'react';
import './OffCanvas.scss';
import { useLanguage } from '../../context/LanguageContext';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OffCanvas = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useLanguage();
  const { t } = useTranslation();

  const toggleOffCanvas = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='offcanvas-wrapper'>
		<button className={`menu-icon`} onClick={toggleOffCanvas}>
			<img src='../images/menu.png' alt='menu icon'/>
		</button>
      	<div className={`offcanvas ${isOpen ? 'show' : ''}`}>
			<div className="offcanvas-content">
				<div className='offcanvas-content-header'>
					<button className="btn btn-secondary" onClick={toggleOffCanvas}>X</button>
					<h2>{t('Menu')}</h2>
				</div>
				<nav className='canvas_nav'>
					<ul>
						<li><NavLink onClick={toggleOffCanvas} to={`/${language}`} end>{t('Home')}</NavLink></li>

						<li><NavLink onClick={toggleOffCanvas} to={`/${language}/oracle-yes-no`}>{t('menu_TaroOracleYesNo')}<img src={`../images/nav_icons/nav_yes_no_1.png`} /></NavLink></li>
						<li><NavLink onClick={toggleOffCanvas} to={`/${language}/three-cards`}>{t('menu_TaroThreeCards')}<img src={`../images/nav_icons/nav_three_cards.png`} /></NavLink></li>
						<li><NavLink onClick={toggleOffCanvas} to={`/${language}/horseshoe`}>{t('menu_TaroHorseshoe')}<img src={`../images/nav_icons/nav_Horseshoe.png`} /></NavLink></li>
						{/* <li><NavLink to={`/${language}/celtic-cross`}>{t('menu_TaroCelticCross')}</NavLink></li>
						<li><NavLink to={`/${language}/tree-of-life`}>{t('menu_TaroTreeOfLife')}</NavLink></li>
						<li><NavLink to={`/${language}/love-triangle`}>{t('menu_TaroLoveTriangle')}</NavLink></li> */}
					</ul>
				</nav>
			</div>
		</div>
		<div className={`offcanvas-bg ${isOpen ? 'show fade' : ''}`} onClick={toggleOffCanvas}></div>
    </div>
  );
};

export default OffCanvas;
